body {
    overflow-x: hidden; /* Horizontalen Überlauf verhindern */
    overflow-y: hidden; /* Vertikalen Überlauf verhindern */
    margin: 0;
    padding: 0;
    height: 100%; /* Verhindert Überlauf des Bodys */
    width: 100%;
  }
  
  .glassContainer {
    background: rgba(19, 19, 19, 0.1);
    border: 1px solid rgba(180, 180, 180, 0.5);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  

  
  .relative-container {
    position: relative;
    z-index: 1;
    overflow: hidden; /* Verhindert Überlauf */
    height: 100%; /* Sichtbarer Bereich */
  }