/* Hintergrund mit angepasstem Farbverlauf */
body {
    background: linear-gradient(to top left, #121929, #121929, #1f2a38);
    color: white;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  
  /* Sidebar */
  .sidebar {
    background: rgba(30, 30, 30, 0.7); /* Transparentes Schwarz */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center; /* Zentriert Icons horizontal */
    height: 100vh;
    width: 48px; /* Schmalere Sidebar */
    border-right: 1px solid rgba(180, 180, 180, 0.5); /* Nur rechts Border */
    transition: width 0.3s ease;
  }
  
  .sidebar-expanded {
    width: 200px; /* Breitere Sidebar */
  }
  
  /* Sidebar Icons */
  .sidebar-icon {
    display: flex;
    align-items: center; /* Vertikal zentriert */
    justify-content: center; /* Standardmäßig horizontal zentriert */
    height: 48px; /* Einheitliche Höhe */
    width: 100%; /* Volle Breite für Hover-Effekt */
    transition: background 0.3s ease, padding 0.3s ease;
    padding: 0 5px;
  }
  
  .sidebar-icon:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .sidebar-expanded .sidebar-icon {
    justify-content: flex-start; /* Links ausrichten */
    padding-left: 10px; /* Abstand zum Rand */
  }
  
  /* Sidebar-Toggle Icon */
  .sidebar-toggle {
    justify-content: center; /* Zentriert im eingeklappten Modus */
    padding: 0;
  }
  
  .sidebar-expanded .sidebar-toggle {
    justify-content: flex-end; /* Rechts ausrichten */
    padding-right: 10px; /* Abstand vom rechten Rand */
  }
  
  /* Sidebar Text */
  .sidebar-text {
    margin-left: 10px;
    font-size: 14px; /* Kleinere Schriftgröße */
    display: none; /* Standardmäßig ausgeblendet */
  }
  
  .sidebar-expanded .sidebar-text {
    display: inline-block; /* Anzeigen, wenn Sidebar erweitert */
  }
  
  /* Icons oben ausrichten */
  .sidebar-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px; /* Abstand von oben */
  }
  
  /* Logout Button */
  .sidebar-bottom {
    margin-top: auto; /* Abstand zum oberen Inhalt */
    margin-bottom: 10px; /* Abstand nach unten */
    width: 100%; /* Volle Breite */
  }
  
  .logout {
    display: flex;
    align-items: center;
    justify-content: center; /* Zentriert im eingeklappten Modus */
    height: 48px; /* Einheitliche Höhe */
    color: white;
    border: none;
    background: #d9534f;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, width 0.3s ease;
    width: calc(100% - 10px); /* Etwas kleiner als die volle Breite */
    margin: 0 auto; /* Zentriert */
  }
  
  .sidebar-expanded .logout {
    justify-content: flex-start; /* Links ausrichten */
    padding-left: 10px; /* Abstand für Text */
    width: 95%; /* Fast volle Breite */
  }
  
  .logout:hover {
    background: #c9302c;
  }
  
  /* Main Content */
  .main-content {
    background: transparent;
    flex: 1;
    padding: 20px;
  }
  
  /* Header */
  .header {
    background: rgba(30, 30, 30, 0.7); /* Transparentes Schwarz */
    border: 1px solid rgba(180, 180, 180, 0.5); /* Heller Rand */
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  /* Bento Grid */
  .bento-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(2, auto);
    gap: 20px;
    margin-top: 20px;
  }
  
  .bento-box {
    background: rgba(19, 19, 19, 0.1);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid rgba(180, 180, 180, 0.5); /* Heller Rand */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Dezenter Schatten */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .bento-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* Etwas stärker beim Hover */
  }  